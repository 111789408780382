import { transparentize } from 'polished';
import styled from 'styled-components';

import { colors } from '../styles/variables';

export const Card = styled.div({
	padding: `32px`,
	marginTop: `16px`,
	marginBottom: '16px',
	backgroundColor: colors.white,
	color: `${transparentize(0.5, colors.black)}`,
	maxWidth: 430,
	boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
	borderRadius: `8px`,
});
