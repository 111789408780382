/**
 * Solutions card
 *
 * @author Anja Bisako <anja.bisako@thingos.io>
 * @version 0.0.1
 */
import { HStack, VStack } from '@domir/react-stacks';
import { Link } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';

import { Card } from './Card';
import { Body, HeaderH3 } from './Typography';

const SolutionH3 = styled(HeaderH3)({
	margin: '16px 0 4px 0',
});

const SolutionBody = styled(Body)({});

interface SolutionCardProps {
	title: string;
	image: React.ReactNode;
	logo: React.ReactNode;
	linkTo?: string;
	linkText?: string;
}

const SolutionCard: React.FC<SolutionCardProps> = props => {
	const { title, logo, image, children, linkTo, linkText } = props;

	return (
		<Card>
			<VStack spacing={32}>
				<HStack spacing={16} justifyContent="space-between" alignItems="center">
					<SolutionH3 style={{ margin: '0px' }}>{title}</SolutionH3>
					<div style={{ width: '30%' }}>{logo}</div>
				</HStack>
				<HStack spacing={16}>{image}</HStack>
				<HStack spacing={16}>
					<SolutionBody style={{ margin: '0' }}>{children}</SolutionBody>
				</HStack>
				{linkTo && (
					<HStack spacing={16}>
						<Link to={linkTo}>{linkText}</Link>
					</HStack>
				)}
			</VStack>
		</Card>
	);
};

export default SolutionCard;
