import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

import Container, { ColorContainer, Row } from '../components/Container';
import { Page } from '../components/Page';
import SolutionCard from '../components/SolutionCard';
import { HeaderH2 } from '../components/Typography';
import { IndexLayout } from '../layouts';

const SolutionsPage = () => {
	const { t } = useTranslation('solutions');

	return (
		<IndexLayout>
			<Page>
				<ColorContainer backgroundColor="#fbfbfb">
					<Container>
						<HeaderH2>{t('solutions.title')}</HeaderH2>

						<Row style={{ flexWrap: 'wrap', justifyContent: 'space-around' }}>
							<SolutionCard
								title={t('solutions.solution1.title')}
								image={<StaticImage src="../content/agv-box-blue.jpg" alt="Box AGV" />}
								logo={
									<StaticImage
										src="../content/partners-rexroth-new.png"
										alt="Bosch Rexroth"
										objectFit="contain"
									/>
								}
							>
								{t('solutions.solution1.text')}
							</SolutionCard>

							<SolutionCard
								title={t('solutions.solution2.title')}
								image={
									<StaticImage
										src="../content/demo-production-line.jpg"
										alt="ARENA2036 Production Line"
									/>
								}
								logo={
									<StaticImage
										src="../content/partners-daimler.png"
										alt="Daimler"
										objectFit="contain"
									/>
								}
							>
								{t('solutions.solution2.text')}
							</SolutionCard>

							<SolutionCard
								title={t('solutions.solution3.title')}
								image={
									<StaticImage
										src="../content/demo-intelligent-floor.jpg"
										alt="Intelligent Floor"
									/>
								}
								logo={
									<StaticImage
										src="../content/partners-rexroth-old.png"
										alt="Bosch Rexroth"
										objectFit="contain"
									/>
								}
							>
								{t('solutions.solution3.text')}
							</SolutionCard>

							<SolutionCard
								title={t('solutions.solution4.title')}
								image={<StaticImage src="../content/demo-pick-by-light.jpg" alt="Pick by light" />}
								logo={
									<StaticImage
										src="../content/partners-hpe.png"
										alt="HP Enterprise"
										objectFit="contain"
									/>
								}
							>
								{t('solutions.solution4.text')}
							</SolutionCard>

							<SolutionCard
								title={t('solutions.solution5.title')}
								image={<StaticImage src="../content/agv-control-blue.jpg" alt="Mobile AGV" />}
								logo={
									<StaticImage src="../content/partners-baer.png" alt="Bär" objectFit="contain" />
								}
							>
								{t('solutions.solution5.text')}
							</SolutionCard>

							<SolutionCard
								title={t('solutions.solution6.title')}
								image={
									<StaticImage
										src="../content/demo-innovation-suitcase-blue.jpg"
										alt="Innovation Suitcase"
									/>
								}
								logo={
									<StaticImage
										src="../content/partners-daimler.png"
										alt="Daimler"
										objectFit="contain"
									/>
								}
							>
								{t('solutions.solution6.text')}
							</SolutionCard>
						</Row>
					</Container>
				</ColorContainer>
			</Page>
		</IndexLayout>
	);
};

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

export default SolutionsPage;
